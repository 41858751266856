import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle),_c(VCardText,[_vm._t("message",[_vm._v(" Are you sure? Do you want to delete this item? ")])],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.emit('delete')}}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.emit('cancel')}}},[_vm._v("No")]),_c(VSpacer)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }